import { computed, reactive } from '@vue/composition-api'

import {
  useAuthStore,
  useAppStore,
  useCardAccountStore,
  storeToRefs,
  useSendMoneyStore,
  useI18nStore,
  useEnvStore,
} from '@galileo/stores'

import {
  CardConfiguration,
  CoreConfiguration,
  SubmitData,
  UIElement,
  UIElementProps,
} from '@adyen/adyen-web'

import {
  ADYEN_CARD_BRAND,
  AdyenData,
  CbObjOnBrand,
  CbObjOnFieldValid,
  CbObjOnBinLookup,
} from './adyenTypes'


export default function () {
  const authStore = useAuthStore()
  const cardAccountStore = useCardAccountStore()
  const { getCardSettings } = storeToRefs(cardAccountStore)

  if (!getCardSettings.value && useSendMoneyStore().getCardPaymentAvailable) {
    cardAccountStore.fetchCardSettings()
  }

  const locale = useI18nStore().i18n.locale

  const countryCode = authStore.userProfile.country

  type environment = 'test' | 'live' | 'live-us' | 'live-au' | 'live-apse' | 'live-in'


  let cardNotSupported = false


  const _environments: { [key: string]: environment } = {
    TEST: 'test',
    Europe: 'live',
    'United States': 'live-us',
    Australia: 'live-au',
    'Asia Pacific & Southeast': 'live-apse',
    India: 'live-in',
  }

  const adyenConfiguration = computed<CoreConfiguration>(() => {
    return {
      locale,
      environment: useEnvStore().isProduction ? _environments.Europe : _environments.TEST,
      clientKey: getCardSettings.value?.clientSideEncryptionKey,
      countryCode,
      showPayButton: false,
      onError: (error: any) => {
        useAppStore().logError('Adyen config error', undefined, error)
      },
      onChange: (state) => {
        adyenData.isValid = state.isValid
      },
    }
  })



  const adyenData = reactive<AdyenData>({
    binValue: '',
    panLastDigits: '',
    isValid: false,
    validationError: null,
    cardFieldsLoaded: false,
    cardBrand: '',
    cardType: null,
    loading: false
  })

  const handleAdyenSubmit = (state: SubmitData, _component: UIElement<UIElementProps>) => {
    adyenData.paymentMethod = state.data.paymentMethod
  }

  const adyenLoaded = () => {
    adyenData.cardFieldsLoaded = true
  }

  const cardConfiguration: CardConfiguration = {
    // Optional configuration
    type: 'scheme',
    brands: [ADYEN_CARD_BRAND.MASTERCARD, ADYEN_CARD_BRAND.VISA],
    brandsConfiguration: {
      visa: {
        icon: './../../icons/visa-black.svg',
      },
      mc: {
        icon: './../../icons/mastercard-black.svg',
      },
    },
    styles: {
      base: {
        fontSize: '14px',
        color: 'rgb(51, 51, 51)',
        fontFamily: 'arial',
      },
      error: {
        color: '#333333',
      },
      validated: {
        color: '333333',
      },
      placeholder: {
        color: '#d8d8d8',
      },
    },
    // Only for Web Components before 4.0.0.
    // For Web Components 4.0.0 and above, configure aria-label attributes in translation files
    // ariaLabels: {
    //   lang: locale,
    //   encryptedCardNumber: {
    //     label: 'Credit or debit card number field',
    //     iframeTitle: 'Iframe for secured card number',
    //     error: 'Message that gets read out when the field is in the error state',
    //   },
    // },
    // Events
    onSubmit: handleAdyenSubmit,
    onLoad: adyenLoaded,
    onFieldValid: onFieldValue,
    onBinValue: onBinValueCallback,
    onBinLookup: onBinLookupCallback,
    // onConfigSuccess: fallbackCallback,
    onBrand: onBrandValueCallback,
    // onError: fallbackCallback,
    // onFocus: fallbackCallback,
  }

  function onFieldValue(field: CbObjOnFieldValid) {
    if (field.fieldType === 'encryptedCardNumber' && field.endDigits) {
      //we use issuerBin as it's 8 digit to use xe bin lookup
      if (field.issuerBin) {
        if (cardNotSupported && adyenData.validationError) {
          adyenData.validationError = null
          cardNotSupported = false
        }
        onXeBinLookup({ binValue: field.issuerBin.toString() })
      }

      adyenData.panLastDigits = field.endDigits
    }
  }


  function onBrandValueCallback(brand: CbObjOnBrand) {
    adyenData.cardBrand = brand.brand
  }

  //we set binValue as pan first digits on the request as it should be 6 digits
  function onBinValueCallback(bin: { binValue: string }) {
    adyenData.binValue = bin.binValue
  }

  function onBinLookupCallback(bin: CbObjOnBinLookup) {

    if (!xeBinLookupTerminated) {
      adyenData.validationError = null
    }


    if (!bin.supportedBrands && !bin.detectedBrands) {
      useAppStore().logException('Adyen -> card not supported')

      cardNotSupported = true
      adyenData.validationError = useI18nStore().$t(
        'ComponentAddPaymentCardModalAdyen.UnsupportedCardBrand'
      ).value
    } else if (!bin.supportedBrands || !bin.detectedBrands) {
      onXeBinLookup({ binValue: adyenData.binValue.toString() })
    }
  }

  let xeBinLookupTerminated = false

  function onXeBinLookup(bin: { binValue: string }) {
    adyenData.loading = true
    xeBinLookupTerminated = false
    useCardAccountStore()
      .requestCardInfo(bin.binValue)
      // TODO - type for data
      .then((data: any) => {
        adyenData.validationError = null
        if (data?.data?.cardType) {
          adyenData.cardType = data.data.cardType
        } else {
          adyenData.cardType = null
        }
        if (data?.error && data.status !== 404 && bin.binValue.length > 5) {

          adyenData.validationError = data.error
          xeBinLookupTerminated = true
        }
      }).finally(() => {
        adyenData.loading = false
      })
  }



  // Sorry, we don’t accept cards from this country. To continue, please use a different card.

  // function fallbackCallback(arg: any): void {
  //   if (arg.action === 'focus' && arg.currentFocusObject === "encryptedCardNumber") {
  //     // useAppStore().logInfo('callback response first arg', arg)
  //     // adyenData.validationError = null
  //   }
  // }

  /*
   * ThreeDS Configuration object to define
   */
  const threeDSConfiguration = {
    challengeWindowSize: '05',
    // Set to any of the following:
    // '02': ['390px', '400px'] -  The default window size
    // '01': ['250px', '400px']
    // '03': ['500px', '600px']
    // '04': ['600px', '400px']
    // '05': ['100%', '100%']
  }

  return {
    threeDSConfiguration,
    adyenData,
    cardConfiguration,
    adyenConfiguration,
  }
}
