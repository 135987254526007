import {
  AdditionalDetailsData,
  AdyenCheckout,
  Card,
  CustomCard,
  ICore,
  PaymentAction,
} from '@adyen/adyen-web'

import {
  useAppStore,
  useCardAccountStore,
  usePaymentsStore,
  useSendMoneyStore,
} from '@galileo/stores'
import { computed, ref, reactive } from '@vue/composition-api'
import useAdyenConfig from '@galileo/composables/useAdyenConfig'
import postCardAuthenticationsV2 from '@galileo/api/launchpad/cardsV2/card-authentications/post'
import useCardHelper from '../useCardHelper/index'
import CardAccount from '@galileo/models/Payment/CardAccount'
import { ADYEN_CARD_TYPE, AdyenData } from '../useAdyenConfig/adyenTypes'

interface AdyenState {
  checkout?: ICore
  onSuccessCallback?: Function
  onAuthRequired?: Function
  onAuthError?: Function
  hosted3dsLoading: boolean
}

export default function useAdyen() {
  let channel: Nullable<BroadcastChannel> = null

  const { logError } = useAppStore()

  const lock = ref(false)

  const { adyenConfiguration, adyenData, threeDSConfiguration, cardConfiguration } =
    useAdyenConfig()

  const adyenState = reactive<AdyenState>({
    hosted3dsLoading: false,
  })

  function handleOnAdditionalDetails(state: AdditionalDetailsData): void {
    const cardAccountStore = useCardAccountStore()
    const referenceId = cardAccountStore.transactionReference
    const threeDSResult = state.data.details.threeDSResult
    const authParams = {
      trxRef: referenceId,
      jwt: threeDSResult,
    }

    postCardAuthenticationsV2
      .exec(authParams)
      .then(async (authenticationResponse) => {
        if (authenticationResponse.data.requirePayerAuthentication) {
          const sendMoneyStore = useSendMoneyStore()
          const encryptedCvn = cardAccountStore.getAdyenData?.card?.encryptedSecurityCode

          sendMoneyStore.form.cardAuthenticationResult = {
            deviceFingerprint: referenceId,
            encryptedCvn: encryptedCvn,
            transactionReference: referenceId,
          }

          if (adyenState.onSuccessCallback) {
            await adyenState.onSuccessCallback()
          } else {
            throw Error('Adyen 3Ds on successCallback is not defined')
          }
        }
      })
      .catch((ex) => {
        logError('Error during 3DS challange with Adyen', undefined, ex)
        if (adyenState.onAuthError) {
          adyenState.onAuthError(ex)
        }
      })
  }

  async function init() {
    if (!adyenConfiguration.value.clientKey) {
      throw Error('Adyen Configuration missing client Key')
    }
    try {
      adyenConfiguration.value.onAdditionalDetails = handleOnAdditionalDetails
      adyenState.checkout = await AdyenCheckout(adyenConfiguration.value)
    } catch (ex) {
      logError('Error creating Adyen checkout object', undefined, ex)
    }
  }

  const addAdyenTabListener = () => {
    adyenState.hosted3dsLoading = true
    const sendMoneyStore = useSendMoneyStore()
    const { adyenCheckoutComplete } = sendMoneyStore

    if (!channel) {
      channel = new BroadcastChannel('adyen-checkout-complete')
    }

    useAppStore().logInfo('Adyen - addAdyenTabListener initalized')

    channel.onmessage = async (event: any) => {
      if (event.data === 'focus-tab' || event.data.closeWindow) {
        window.focus()
        return
      }

      if (!adyenCheckoutComplete && !lock.value) {
        channel?.postMessage({
          closeWindow: true,
        })

        lock.value = true

        if (event.data.adyenCheckoutPayload) {
          const redirectResult = {
            data: {
              details: {
                threeDSResult: event.data.adyenCheckoutPayload.redirectResult,
              },
            },
          }
          handleOnAdditionalDetails(redirectResult)
        } else {
          useAppStore().logError('fetching Adyen redirect result parameter has failed!')
        }
      }
    }
  }

  const createHosted3dsForm = (action: any) => {
    let windowName = 'w_' + Date.now() + Math.floor(Math.random() * 100000).toString()
    let form = document.createElement('form')

    form.setAttribute('method', 'post')
    form.setAttribute('action', action.url as string)
    form.setAttribute('target', windowName)

    let PaReq = document.createElement('input')
    PaReq.setAttribute('type', 'hidden')
    PaReq.setAttribute('name', 'PaReq')
    PaReq.setAttribute('value', action.data.PaReq)

    let MD = document.createElement('input')
    MD.setAttribute('type', 'hidden')
    MD.setAttribute('name', 'MD')
    MD.setAttribute('value', action.data.MD)

    form.appendChild(PaReq)
    form.appendChild(MD)

    document.body.appendChild(form)
    form.submit()
  }

  const closeAdyenBroadcastChannel = () => {
    if (channel) {
      adyenState.hosted3dsLoading = false
      channel.close()
      channel = null
    }
  }

  async function mountFromAction(action: PaymentAction, container: string = '#my-container') {
    useCardAccountStore().cardAuthorisationType = action.type
    if (!adyenState.checkout) {
      useAppStore().logError('Adyen Checkout not initialized')
      throw new Error('Adyen Checkout not initialized')
    }

    if (action.type === 'redirect') {
      createHosted3dsForm(action)
    } else {
      adyenState.checkout.createFromAction(action, threeDSConfiguration).mount(container)
    }
  }

  async function mountCardFields(container: string = '#customCard-container'): Promise<Card> {
    if (!adyenState.checkout) {
      useAppStore().logError('Adyen Checkout not initialized')
      throw new Error('Adyen Checkout not initialized')
    }

    const checkout = await adyenState.checkout.initialize()
    return new Card(checkout, cardConfiguration).mount(container)
  }

  async function mountCVVField(container: string = '#customCard-container'): Promise<CustomCard> {
    if (!adyenState.checkout) {
      useAppStore().logError('Adyen Checkout not initialized')
      throw new Error('Adyen Checkout not initialized')
    }
    const selectedPayment = usePaymentsStore().getSelectedPaymentMethod() as CardAccount

    if (selectedPayment.isCard && selectedPayment.type) {
      const brand = useCardHelper().getBrandByCardType(selectedPayment.type)
      cardConfiguration.type = selectedPayment.isDebitCard
        ? ADYEN_CARD_TYPE.DEBIT
        : ADYEN_CARD_TYPE.CREDIT
      cardConfiguration.brands = [brand]
    } else {
      throw Error('Selected payment method is not a card or type missing')
    }

    cardConfiguration.onFieldValid = adyenState.onSuccessCallback as any // TODO - onSuccessCallback type

    const checkout = await adyenState.checkout.initialize()
    const customCard = new CustomCard(checkout, cardConfiguration).mount(container)

    return customCard
  }

  return {
    init,
    addAdyenTabListener,
    closeAdyenBroadcastChannel,
    mountFromAction,
    state: adyenState,
    mountCardFields,
    adyenData: computed<AdyenData>(() => adyenData),
    mountCVVField,
  }
}
